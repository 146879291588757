<template>
    <div class="init">
        <el-dialog v-model="dialogBool" lock-scroll
            :title="style_ === 'add'?$t('button.add'):$t('button.update')" width="600" >
            <el-form :model="fromValue" label-width="auto"  label-position="top" ref='form_' class="fromClass"
                :rules='rules'>
                <tanAndAppid @getTenantIdAndAppid='getTenantIdAndAppid' :style_='style_' ref='tanAndAppid_'/>
                <el-form-item :label="$t('currencySetNft.name3')" prop="allCoin">
                    <el-select v-model="fromValue.allCoin" :teleported='false' :placeholder="$t('inputTit.xuanze')"  :disabled='style_ === "update"?true:!fromValue.userId || !fromValue.appId' @change="getCoin_">
                        <el-option :value="item" v-for='item in list' :key = 'item.tenantCoinId'> 
                            <span style="float: left;color:#666">{{item.chain}}</span>
                            <span style="float: right;color:#ccc;font-weight:400">{{item.coin}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('otherWallat.text3')" prop="allCoin">
                    <el-radio-group v-model="fromValue.isWallet" size="large" @change="changeFun_" :disabled='style_ === "update"'>
                        <el-radio-button :label="1">{{$t('otherWallat.text4')}}</el-radio-button>
                        <el-radio-button :label="2">{{$t('otherWallat.text5')}}</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <template v-if='fromValue.isWallet === 2'>
                    <el-form-item :label="$t('otherWallat.text6')" prop="walletAddress"  :rules="rules_">
                        <el-input v-model="fromValue.walletAddress" disabled  :placeholder="$t('inputTit.xuanze')">
                            <template #append v-if='fromValue.allCoin'>
                                <el-button text type="primary" @click="chooseCoin1">{{$t('button.checks')}}</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                </template>
                <el-form-item :label="$t('otherWallat.name1')" prop="walletName">
                    <el-input v-model="fromValue.walletName" :placeholder="$t('inputTit.shuru')" :disabled='fromValue.isWallet === 2?true:false'/>
                </el-form-item>
                <el-form-item :label="$t('otherWallat.name2')" prop="priority">
                    <el-input v-model="fromValue.priority" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.name29')" prop="googleCode">
                    <el-input v-model="fromValue.googleCode"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogBool = false">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.enter')}}</el-button>
                </span>
            </template>
        </el-dialog>
         <el-dialog v-model="innerVisible1"
            width="900"
            :title="$t('otherWallat.text6')"
            append-to-body
        >
            <addrView v-if='innerVisible1' @getCoin1='getCoin1' ref='addrView_'/>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,nextTick,defineEmits,getCurrentInstance } from 'vue'
    import { userStore } from '@/store/user'
    import addrView from './addr.vue'
    import tanAndAppid from '../commom/tenAndAppid.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        userId: '',tenantId:'',
        appId:'',
        allCoin:'',chain:'',protocol:'',coin:'',coinId:'',
        walletAddress:'',walletName:'',
        googleCode:'',
        protocolType: 1,userType: 2,
        isWallet: 1,priority:1,
        walletType:2,

    })//userStore().getUserInfo[0].username
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.userId = userStore().getUserInfo.tenantId
    // fromValue
    const emit = defineEmits(['successFun'])
    const innerVisible1 = ref(false)
    const form_ = ref(null)
    const rules = ref(null);rules.value = addRules.mainRules
    const dialogBool = ref(false)
    const style_ = ref('add')
    const rules_ = ref([])
    const addrView_ = ref(null)
    const list = ref([])
    const tanAndAppid_ = ref(null)
    
    const getTenantIdAndAppid = (e)=>{//获得公共组件中的app和商户
        if(e){
            fromValue.userId = e.tenantId
            fromValue.tenantId = e.tenantId
            fromValue.appId = e.appId
            Api_.getCurrencySetList({...e,isBaseCoin: 1}).then(res=>{
                if(res.code === 0){
                    list.value = res.data.records
                    let array_ = ['allCoin','chain','protocol','coin','coinId','walletName','walletAddress']
                    for(let item of array_){
                        fromValue[item] = ''
                    }
                }
            })
        }
    }
    const changeFun_ = (e)=>{
        if(e === 2){
            rules_.value = [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' }]
        }else{
            fromValue.walletAddress = '';
            rules_.value = []
        }
    }
    const getCoin_ = (e)=>{
        fromValue.allCoin = e.chain + '/'+e.coin;
        fromValue.chain = e.chain;
        fromValue.protocol = e.protocol;
        fromValue.coin = e.coin;
        fromValue.coinId = e.coinId;
    }
    const chooseCoin1 = ()=>{
        innerVisible1.value = true
        nextTick(()=>{
            let obj_ = {
                chain:fromValue.chain,
                tenantId:fromValue.userId,
                appId:fromValue.appId,
                userType: 2,
                isBaseCoin: 1,
                walletTypes: '1,2,3'
            }
            if(fromValue.coin){
                obj_.coin = fromValue.coin
            }
            addrView_.value.init_(obj_)
        })
    }
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
            let obj_ = JSON.parse(JSON.stringify(fromValue))
            delete obj_.allCoin
            delete obj_.googleCode
            obj_.tenantId = obj_.userId
            if(style_.value === 'add'){
                Api_.addAssetswalletinfoStatus(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        dialogBool.value = false
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }else{
                Api_.upAssetswalletinfoStatus(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        dialogBool.value = false
                        ElNotification({
                            message: $t('alert.succUpdate'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }

            }
        })
    }
    const setData = (scope,style)=>{//获得列表数据
        dialogBool.value = true;style_.value = style
        nextTick(()=>{
            if(style_.value === 'add'){
                for(let i in fromValue){
                    fromValue[i] = ''
                }
                initData()
                tanAndAppid_.value.setApp()
            }else{//修改遍历对象
                for(let i in scope){
                    fromValue[i] = scope[i]
                }
                tanAndAppid_.value.setData(scope.tenantName,scope.appName)
                fromValue.isLimit = fromValue.isLimit?fromValue.isLimit.toString():'0'
                fromValue.allCoin = fromValue.chain +'/'+fromValue.coin;
                fromValue.googleCode = ''
            } 
        })
        
    }
    const initData = ()=>{//参数初始化
        fromValue.protocolType = 1
        fromValue.priority = 1
        fromValue.walletType = 2
        fromValue.userType = 2
        fromValue.isWallet = 1
    }
    const getCoin1 = (obj)=>{
        innerVisible1.value = false
        fromValue.walletAddress = obj.walletAddress;
        fromValue.walletName = obj.walletName;
    }
    defineExpose({setData})
</script>
<style lang="scss" scoped>
    
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
</style>